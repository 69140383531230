// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bG_kw";
export var caseStudyBackground__lineColor = "bG_kt";
export var caseStudyHead__imageWrapper = "bG_kp";
export var caseStudyProjectsSection = "bG_kx";
export var caseStudyQuote__bgLight = "bG_kD";
export var caseStudyQuote__bgRing = "bG_kr";
export var caseStudySolution__ring = "bG_kB";
export var caseStudyVideo__ring = "bG_kG";
export var caseStudy__bgDark = "bG_km";
export var caseStudy__bgLight = "bG_kl";