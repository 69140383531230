import { Link } from 'gatsby'
import { StaticImage, getImage } from 'gatsby-plugin-image'

import solutionBgRingOne from '~/assets/images/case-study/africar/bg-rings-one.svg?file'
import MainLayout from '~/components/layouts/MainLayout'
import ContactFormSection from '~/components/shared/ContactFormSection'
import LazyHydrate from '~/components/shared/LazyHydrate'
import { CommonImageProps } from '~/types/common-props'
import { fileToImageLikeData } from '~/utils'
import CaseStudyBackground from '~/views/CaseStudies/components/CaseStudyBackground'
import CaseStudyCta from '~/views/CaseStudies/components/CaseStudyCta'
import CaseStudyHead from '~/views/CaseStudies/components/CaseStudyHead'
import CaseStudyPreview from '~/views/CaseStudies/components/CaseStudyPreview'
import CaseStudyPreviewHead from '~/views/CaseStudies/components/CaseStudyPreviewHead'
import CaseStudyQuote from '~/views/CaseStudies/components/CaseStudyQuote'
import CaseStudySolution from '~/views/CaseStudies/components/CaseStudySolution'
import CaseStudySolutionHead from '~/views/CaseStudies/components/CaseStudySolutionHead'
import CaseStudyTech from '~/views/CaseStudies/components/CaseStudyTech'
import CaseStudyVideoCase from '~/views/CaseStudies/components/CaseStudyVideo'

import * as containerStyles from './Africar.module.scss'
import useAfricarStaticQuery from './useAfricarStaticQuery'

const imageProps: CommonImageProps = {
  width: 80,
  height: 80,
}

const quotes = {
  client: {
    name: 'CEO',
    position: 'Marketplace Investment Company, Australia',
  },
  be: {
    name: 'Maksym',
    position: 'Tech Lead & Rails expert at Codica',
  },
}

const previewBabelTitle = 'Insurance Progressive Web App'
const previewWownerTitle = 'Intelligent Job Match System'

const CaseStudiesAfricar = () => {
  const query = useAfricarStaticQuery()

  const bannerCase = getImage(fileToImageLikeData(query.bannerCase))
  const solutionDeliveredOne = getImage(
    fileToImageLikeData(query.solutionDeliveredOne),
  )
  const solutionDeliveredTwo = getImage(
    fileToImageLikeData(query.solutionDeliveredTwo),
  )
  const solutionDeliveredThree = getImage(
    fileToImageLikeData(query.solutionDeliveredThree),
  )
  const solutionDeliveredFour = getImage(
    fileToImageLikeData(query.solutionDeliveredFour),
  )
  const solutionDeliveredFive = getImage(
    fileToImageLikeData(query.solutionDeliveredFive),
  )
  const solutionDeliveredSix = getImage(
    fileToImageLikeData(query.solutionDeliveredSix),
  )
  const videoCaseImage = getImage(fileToImageLikeData(query.videoCaseImage))

  return (
    <MainLayout isTransparent scrollTop>
      <div className={containerStyles.caseStudy__bgLight}>
        <CaseStudyHead
          title="Multi-vendor vehicle online marketplace"
          maxWidthTitle="550px"
          subtitle="Learn how Codica helped create the first auto marketplace in Africa and optimize it for mobile devices."
          image={bannerCase}
          classNameImageWrapper={containerStyles.caseStudyHead__imageWrapper}
          imageAlt="Fast-loading multi-vendor car marketplace"
          imageTitle="Fast-loading multi-vendor auto marketplace"
          domain="Automotive"
          location="Australia / Africa"
          timeline="September 2018 - May 2021"
          services={
            <>
              <Link to="/services/ui-ux-design/" key="designKey">
                UX/UI design services
              </Link>
              , Front-end and Back-end development,
              <Link
                to="/services/mvp-development/"
                className="ml5"
                key="mvpDevKey"
              >
                MVP development
              </Link>
            </>
          }
          team="1 Project Manager, 1 UX/UI Designer, 3 Ruby on Rails Developers, 1 QA Engineer"
          technologies="Ruby on Rails, PostgreSQL, Redis, AWS"
          isQuote
        />

        <CaseStudyQuote
          quote={[
            {
              text: '“We are delighted to collaborate with Codica. In conversation with the Codica team, we decided to choose Ruby on Rails as a core technology for marketplace development, which turned out to be the best decision for our needs, and together we quickly launched an MVP for our automotive marketplace.”',
            },
          ]}
          photo={
            <StaticImage
              src="../../../../assets/images/case-study/africar/avatar-client-dark-blue.png"
              alt={`${quotes.client.name}, ${quotes.client.position} at Codica`}
              title={`${quotes.client.name}, ${quotes.client.position}`}
              {...imageProps}
            />
          }
          bgRing={containerStyles.caseStudyQuote__bgRing}
          name={quotes.client.name}
          position={quotes.client.position}
        />
      </div>

      <CaseStudyBackground
        backgroundColor={containerStyles.caseStudy__bgDark}
        lineColor={containerStyles.caseStudyBackground__lineColor}
        bgTextLeft="The customer contacted us with a very ambitious project idea of creating the first online auto marketplace in Africa. The solution would cover multiple regions of Africa and South America and connect buyers with car dealers and private sellers."
        bgTextRight="The client’s request was to build a multi-seller marketplace where users could easily buy or sell cars, trucks, and motorbikes. The key requirements for the future solution were simple workflow, fast load, and mobile optimization."
        sectionBusiness={[
          {
            label:
              'Create a minimum viable product of an auto marketplace in a short time frame.',
          },
          {
            label:
              'Build a scalable multi-vendor marketplace that would allow users: to sell their new or second-hand vehicles; to find the vehicle they need based on specific parameters.',
          },
          {
            label: (
              <>
                Adapt the
                <Link
                  key="africarMultiVendorKey"
                  className="mx5"
                  to="/services/multi-vendor-marketplace-development/"
                >
                  multi-vendor marketplace development
                </Link>
                marketplace development solution for mobile devices with a weak
                Internet connection.
              </>
            ),
          },
        ]}
        sectionValueDelivered={[
          {
            label:
              'Created a working MVP solution on time and budget to raise initial investments. It was further developed into a complex and high-performing auto marketplace.',
          },
          {
            label:
              'Developed an extensive platform with 90+ local vehicle marketplaces online successfully operating across Africa. 72 local currencies and 15 languages were implemented in the process of building and publishing new websites.',
          },
          {
            label:
              'Built a progressive web application (PWA) to optimize the platform for smartphone owners. 80% of potential users go online via smartphones with a weak Internet connection. By implementing the PWA technology, we made sure that buyers and suppliers can easily access the platform even if the Internet connection is slow.',
          },
          {
            label:
              'Created a fast-loading web solution. This way, users can freely navigate the website and explore its content on the go. Besides, high loading speed positively affected the number of viewers, thus increasing the platform’s traffic.',
          },
        ]}
      />

      <div className={containerStyles.caseStudy__bgLight}>
        <CaseStudyQuote
          quote={[
            {
              text: '“Codica team coped with the task at hand perfectly. The platform loads fast, and it’s highly scalable - we’re adding more and more new websites. It’s also fully localizable - we can easily translate content, add new currencies and languages.',
            },
            {
              text: 'In terms of UX, the website is very user-friendly for both vendors and buyers. These guys are experts in building marketplace development solutions on time and budget. I highly recommend Codica; the team is highly professional and very dedicated.”',
            },
          ]}
          photo={
            <StaticImage
              src="../../../../assets/images/case-study/africar/avatar-client-dark-blue.png"
              alt={`${quotes.client.name}, ${quotes.client.position} at Codica`}
              title={`${quotes.client.name}, ${quotes.client.position}`}
              {...imageProps}
            />
          }
          bgRing={containerStyles.caseStudyQuote__bgRing}
          name={quotes.client.name}
          position={quotes.client.position}
        />
      </div>

      <CaseStudyCta
        backgroundColor={containerStyles.caseStudy__bgDark}
        title="Do you want to build a multi-vendor marketplace?"
        description="We apply best practices to help you save costs and launch quickly."
        nameButton="Talk to an expert"
        linkButton="/contacts/"
      />

      <div className={containerStyles.caseStudy__bgLight}>
        <CaseStudySolutionHead title="Solution delivered" />
        <CaseStudySolution
          image={solutionDeliveredOne}
          title="Progressive web application"
          description="We implemented advanced PWA technology to provide both smartphone and desktop users with a seamless user experience. Thanks to their cross-platform nature, progressive web apps can meet the basic needs of the customers regardless of the device, be it mobile or desktop. Also, PWAs work great both on Android and iOS devices."
          alt="PWA for the car marketplace"
          imageTitle="Progressive web app for the auto marketplace"
        />
        <CaseStudySolution
          isImageSmall
          isReverse
          image={solutionDeliveredTwo}
          title="Multi-site marketplace platform"
          description="The automotive marketplace delivered is noted for its scalability. It means that the marketplace development team does not have any difficulties building and publishing a new website for a particular country. Each website supports multiple languages and currencies. Apart from that, each site has got a number of specific settings, including logo, colors, etc."
          alt="Multi-site automotive marketplace"
          imageTitle="Multi-site online auto marketplace"
        />
        <CaseStudySolution
          image={solutionDeliveredThree}
          title="UX and UI"
          description="Our team focused on the creation of a marketplace, initially customized for mobile use. For this purpose, we made sure that the behavior, general marketplace functionality, and appearance of the platform were fully in line with mobile UI. In terms of UX, the marketplace development solution is very user-friendly and convenient for both buyers and vendors."
          alt="Great UX/UI design of the auto marketplace"
          imageTitle="Sleek design of the car marketplace"
        />
      </div>

      <div className={containerStyles.caseStudy__bgDark}>
        <CaseStudySolution
          isReverse
          isTextWhite
          image={solutionDeliveredFour}
          title="Ad creation"
          description="In the process of vehicle marketplace development, our team focused on mobile usability. We carefully designed the most convenient Ad creation feature. The marketplace for vehicles enables sellers to create and publish a new ad within 30-60 seconds."
          alt="Ad posting functionality on the car marketplace"
          imageTitle="Ad posting feature page on the auto marketplace"
        />
        <CaseStudySolution
          isTextWhite
          image={solutionDeliveredFive}
          title="Easy communication between buyers and sellers"
          description={
            <>
              We implemented a responsive Contact feature to enable buyers to
              contact the vendor of a chosen vehicle. Since the
              <Link
                to="/services/ecommerce-marketplace-development/"
                className="mx5"
                key="ecommerceServiceKey"
              >
                ecommerce marketplace solution
              </Link>
              is aimed at smartphone users, the Call option was added for
              immediate contact.
            </>
          }
          alt="Communication functionality on the car marketplace"
          imageTitle="Communication between buyers and sellers on the auto marketplace"
          bgRings
          bgRingImage={solutionBgRingOne}
          bgRingClass={containerStyles.caseStudySolution__ring}
        />
        <CaseStudySolution
          isReverse
          isTextWhite
          image={solutionDeliveredSix}
          title="Advanced search filters"
          description="This integrated feature helps buyers find the right vehicle from a large list of cars, trucks, and bikers. Users specify the key parameters such as price, make, city, and condition and choose the transportation facility that satisfies their primary requirements."
          alt="Advanced search filters on the car marketplace"
          imageTitle="Extensive search filters on the auto marketplace"
        />
      </div>

      <LazyHydrate whenVisible>
        <CaseStudyVideoCase
          title="How it works"
          description="The video below explains how the online auto marketplace works. You can see cars available on the platform, filter search results based on specific parameters. Finally, you can choose the most suitable vehicle and contact its owner."
          ringsClass={containerStyles.caseStudyVideo__ring}
          image={videoCaseImage}
          videoLink="https://www.youtube.com/embed/ugbjKKmtLdE"
        />
      </LazyHydrate>

      <CaseStudyCta
        backgroundColor={containerStyles.caseStudy__bgDark}
        title="Do you need a reliable development partner to build your marketplace?"
        description="Our expert team applies best practices to help you save time and budget."
        nameButton="Learn more"
        linkButton="/services/online-marketplace-development/"
      />

      <div className={containerStyles.caseStudyQuote__bgLight}>
        <CaseStudyQuote
          quote={[
            {
              text: '“We were excited to take up this ambitious project and build the first online auto marketplace in Africa. At first, we created a working minimum viable product so that the customer could present it to investors.',
            },
            {
              text: 'However, eventually, we turned the MVP into an extensive automotive marketplace with more than 90 local vehicle marketplaces online. It was a great and valuable project for our team, and we are happy to witness its growth and success.”',
            },
          ]}
          photo={
            <StaticImage
              src="../../../../assets/images/avatars/Max.png"
              alt={`${quotes.be.name}, ${quotes.be.position} at Codica`}
              title={`${quotes.be.name}, ${quotes.be.position}`}
              {...imageProps}
            />
          }
          bgRing={containerStyles.caseStudyQuote__bgRing}
          name={quotes.be.name}
          position={quotes.be.position}
        />
      </div>

      <div className={containerStyles.caseStudy__bgDark}>
        <CaseStudyTech
          technologies={[
            { label: 'Ruby On Rails', icon: 'ror' },
            { label: 'PostgreSQL', icon: 'sql' },
            { label: 'ActiveAdmin', icon: 'activeadmin' },
            { label: 'Sidekiq', icon: 'sidekiq' },
            { label: 'Redis', icon: 'redis' },
            { label: 'JQuery', icon: 'jquery' },
            { label: 'RSpec', icon: 'rspec' },
          ]}
          integrations={[
            { label: 'AWS', icon: 'aws' },
            { label: 'Paypal', icon: 'paypal' },
            { label: 'Direct Pay', icon: 'directpay' },
            { label: 'SendGrid', icon: 'sendgrid' },
            { label: 'Sentry', icon: 'sentry' },
            { label: 'Skylight', icon: 'skylight' },
          ]}
        />
      </div>

      <CaseStudyQuote
        isBottom
        quote={[
          {
            text: '“For now, we have been working with Codica for 3 years already, and the results have been great. The initial multi-vendor app was perfect for raising investment, and we further developed it into a complex and high-performing online auto marketplace.”',
          },
        ]}
        photo={
          <StaticImage
            src="../../../../assets/images/case-study/africar/avatar-client-dark-blue.png"
            alt={`${quotes.client.name}, ${quotes.client.position} at Codica`}
            title={`${quotes.client.name}, ${quotes.client.position}`}
            {...imageProps}
          />
        }
        bgRing={containerStyles.caseStudyQuote__bgRing}
        name={quotes.client.name}
        position={quotes.client.position}
      />

      <div className={containerStyles.caseContactFormSection}>
        <ContactFormSection title="Let’s build a great custom product together!" />
      </div>

      <div className={containerStyles.caseStudyProjectsSection}>
        <div className="container case-wrapper-xl">
          <div className="row">
            <CaseStudyPreviewHead title="Other projects" />
            <CaseStudyPreview
              title={previewBabelTitle}
              color="babelColor"
              image={
                <StaticImage
                  src="../../../../assets/images/case-study/preview-other-project/babel.png"
                  alt={`Case study: ${previewBabelTitle} | Codica`}
                  title={`Case study: ${previewBabelTitle}`}
                  placeholder="blurred"
                  objectFit="fill"
                  width={350}
                />
              }
              url="insurance-progressive-web-application"
            />
            <CaseStudyPreview
              title={previewWownerTitle}
              color="wownerColor"
              image={
                <StaticImage
                  src="../../../../assets/images/case-study/preview-other-project/wowner.png"
                  alt={`Case study: ${previewWownerTitle} | Codica`}
                  title={`Case study: ${previewWownerTitle}`}
                  placeholder="blurred"
                  objectFit="fill"
                  width={350}
                />
              }
              url="intelligent-job-matching-service"
            />
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default CaseStudiesAfricar
